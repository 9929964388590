import React from 'react';
import {
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentBody,
  EuiTitle,
  EuiPageSideBar,
  EuiSpacer,
  EuiLoadingContent,
  EuiText,
  EuiAccordion,
  EuiPanel,
} from '@elastic/eui';

import Layout from "../components/layout"
import SEO from "../components/seo"

import BrokerCard from '../components/broker_card';
import BrokerFilters from '../components/filters/index_filters';
import EmptyResult from '../components/empty_result';



import { filtersStore } from './../apollo/client';

  
  export default ({ pageContext: { category, brokers } }) => {

    const [filterBySpread, setFilterBySpread] = React.useState(["high", "mid", "low"]);
    const [filterByEstablished, setfilterByEstablished] = React.useState({ min: 0, max: 3000 });
    const [filterByLeverage, setfilterByLeverage] = React.useState({ min: 0, max: 3000 });
    const [filterByPlatforms, setfilterByPlatforms] = React.useState([]);
    const [filterByInstruments, setfilterByInstruments] = React.useState([]);
    const [filterByRegulators, setfilterByRegulators] = React.useState([]);
    const [filterByFundingMethods, setfilterByFundingMethods] = React.useState([]);
    const [filterByBrokerTypes, setfilterByBrokerTypes] = React.useState([]);
    const [filterByCountries, setfilterByCountries] = React.useState([]);
    const [brokersView, setBrokersView] = React.useState(brokers);
    
    filtersStore({
        spreads: {
        val: filterBySpread,
        update: item => setFilterBySpread(item),
        filter: item => {
            if((item.node.spreads && filterBySpread.includes(item.node.spreads.slug)) || filterBySpread.length === 3) {
            return item;
            }
        }
        },
        established: {
        val: filterByEstablished,
        update: item => setfilterByEstablished(item),
        filter: item => {
            if(item.node.established && item.node.established >= filterByEstablished.min && item.node.established <= filterByEstablished.max) {
            return item;
            }
        }
        },
        max_leverage: {
        val: filterByLeverage,
        update: item => setfilterByLeverage(item),
        filter: item => {
            if((item.node.max_leverage && item.node.max_leverage >= filterByLeverage.min && item.node.max_leverage <= filterByLeverage.max) ||
                (filterByLeverage.min === 0 && filterByLeverage.max === 3000)) {
            return item;
            }
        }
        },
        platforms: {
        val: filterByPlatforms,
        update: item => setfilterByPlatforms(item),
        filter: item => {
            if((item.node.platforms && item.node.platforms
                .filter(elem => filterByPlatforms.includes(elem.contentful_id))
                .length > 0)
                || filterByPlatforms.length === 0
            ) return item; 
        }
        },
        instruments: {
        val: filterByInstruments,
        update: item => setfilterByInstruments(item),
        filter: item => {
            if((item.node.instruments && item.node.instruments
                .filter(elem => filterByInstruments.includes(elem.contentful_id))
                .length > 0)
                || filterByInstruments.length === 0
            ) return item; 
        }
        },
        regulators: {
        val: filterByRegulators,
        update: item => setfilterByRegulators(item),
        filter: item => {
            if(((item.node.regulators && item.node.regulators
                .filter(elem => filterByRegulators.includes(elem.contentful_id))
                .length > 0)
                || filterByRegulators.length === 0)
            ) return item; 
        }
        },
        funding_methods: {
        val: filterByFundingMethods,
        update: item => setfilterByFundingMethods(item),
        filter: item => {
            if(((item.node.funding_methods && item.node.funding_methods
                .filter(elem => filterByFundingMethods.includes(elem.contentful_id))
                .length > 0)
                || filterByFundingMethods.length === 0)
            ) return item; 
        }
        },
        broker_types: {
        val: filterByBrokerTypes,
        update: item => setfilterByBrokerTypes(item),
        filter: item => {
            if(((item.node.broker_types && item.node.broker_types
                .filter(elem => filterByBrokerTypes.includes(elem.contentful_id))
                .length > 0)
                || filterByBrokerTypes.length === 0)
            ) return item; 
        }
        },
        countries: {
        val: filterByCountries,
        update: item => setfilterByCountries(item),
        filter: item => {
            if(((item.node.countries && item.node.countries
                .filter(elem => filterByCountries.includes(elem.contentful_id))
                .length > 0)
                || filterByCountries.length === 0) 
            ) return item; 
        }
        }
    })
    
    const store = filtersStore();
    
    

    
    const brokersFiltered = brokersView
        .filter(store.spreads.filter)
        .filter(store.established.filter) 
        .filter(store.max_leverage.filter) 
        .filter(store.platforms.filter) 
        .filter(store.instruments.filter) 
        .filter(store.regulators.filter) 
        .filter(store.funding_methods.filter) 
        .filter(store.broker_types.filter) 
        .filter(store.countries.filter) 
    
        
    const brokersRender = brokersFiltered.map((element) => {
        return <BrokerCard key={element.node.slug} data={element.node} />
    }) 
    
    return (
    <Layout>
    <SEO 
        title={category.name}
        canonical={`/${category.slug}/`}
    />
    
    <EuiPage restrictWidth={1230} >
        <EuiPageSideBar style={{ minWidth: 270 }}>

    
            <div className="hide_m hide_l hide_xl">

                <EuiTitle size="s">
                    <h1>{category.name}</h1>
                </EuiTitle>

                <EuiSpacer size="s" />



                <EuiPanel>
                    <EuiText size="s" dangerouslySetInnerHTML={{__html: category.preview.preview}} />
                    <EuiSpacer size="s" />
                    <EuiAccordion
                        id="accordion"
                        buttonContent="Подробнее"
                        paddingSize="s"
                    >

                        <EuiText size="s" dangerouslySetInnerHTML={{__html: category.text.text}} />
                        
                    </EuiAccordion>
                </EuiPanel>
            </div>

            <EuiSpacer size="m" />

            <div className="hide_xs hide_s">
                <BrokerFilters />
            </div>
        
        </EuiPageSideBar>

    
    
        <EuiPageBody>

            <div className="hide_xs hide_s">
                <EuiTitle size="m">
                    <h1>{category.name}</h1>
                </EuiTitle>
                <EuiPanel>
                    <EuiText size="s" dangerouslySetInnerHTML={{__html: category.preview.preview}} />
                    <EuiSpacer size="s" />
                    <EuiAccordion
                        id="accordion"
                        buttonContent="Подробнее"
                        paddingSize="s"
                    >
                        <EuiText size="s" dangerouslySetInnerHTML={{__html: category.text.text}} />
                    </EuiAccordion>
                </EuiPanel>
                <EuiSpacer size="m" />
            </div>

            <EuiPageContent>
            
                <EuiPageContentBody>
                    {brokersView.length === 0 ? <EuiLoadingContent lines={10} /> : (brokersRender.length > 0 ? brokersRender : <EmptyResult />)}
                </EuiPageContentBody>
        
            </EuiPageContent>

        </EuiPageBody>
    </EuiPage>
    </Layout>
    )
  }